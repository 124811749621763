import React, { type ReactNode, useEffect } from 'react';
import LoginLayout from 'components/login/login-layout';
import LoginLeftContent from 'components/login/login-left-content';
import styled from 'types/theme.types';
import { Navigate, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useLastLoginMethod, useLoginLock } from 'hooks/use-login-lock';
import LockContainer from 'components/login/lock-container';
import Link from 'components/common/link';
import { FormattedMessage } from 'react-intl';
import Loader from 'components/common/loader';
import type { LoginMethod } from '../../../stores/mobx/auth.store';

const CommonLoginPage: React.FC<{ loginMethod: LoginMethod }> = ({ loginMethod }) => {
  const { redirectTo, isAuthenticated, isCallbackPage } = useLoginLock(loginMethod);

  if (isCallbackPage()) {
    return <Loader />;
  }

  if (isAuthenticated) {
    return (
      <>
        <Loader />
        <Navigate replace to={redirectTo} />
      </>
    );
  }

  return <Content loginMethod={loginMethod} />;
};

const Content: React.FC<{ loginMethod: LoginMethod }> = ({ loginMethod }) => {
  const PAGE_TITLE_ID = `login.${loginMethod}.login`;
  const navigate = useNavigate();
  const { setLastLoginMethod } = useLastLoginMethod();

  useEffect(() => {
    setLastLoginMethod(loginMethod);
  }, [loginMethod, setLastLoginMethod]);

  return (
    <LoginLayout
      pageTitleId={PAGE_TITLE_ID}
      leftContent={<LoginLeftContent />}
      rightContent={
        <>
          <LockContainer />
          <BackToLoginMessage
            data-e2e={`${loginMethod}-login-go-back`}
            id={`login.${loginMethod}.go.back`}
            values={{
              a: (text: string | ReactNode) => (
                <Link
                  messageId={text}
                  plainText
                  onClick={() => {
                    setLastLoginMethod('social');
                    navigate('/login');
                  }}
                />
              )
            }}
          />
        </>
      }
    />
  );
};

const BackToLoginMessage = styled(FormattedMessage)`
  font-size: 17px;
`;

const CommonLoginPageObserver = observer(CommonLoginPage);

export const SSOLoginPage = () => <CommonLoginPageObserver loginMethod="sso" />;
export const PasswordlessLoginPage = () => <CommonLoginPageObserver loginMethod="passwordless" />;
