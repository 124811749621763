import type { CSSProperties, ReactElement, ReactNode } from 'react';
import React from 'react';
import styled from 'types/theme.types';
import { FormattedMessage } from 'react-intl';
import { Skeleton } from 'antd';
import { Alert } from 'antd';
import { css } from '@emotion/react';
import { DEFAULT_SHADOW } from 'constants/themes.constants';
import Link from 'components/common/link';

export const headerPaddingCss = css`
  padding: 15px 24px;
  position: relative;
`;

interface Props {
  id?: string;
  title?: ReactElement | ReactNode;
  style?: CSSProperties;
  inline?: boolean;
  withTitleDivider?: boolean;
  loading?: boolean;
  className?: string;
  allowBodyScroll?: boolean;
  fullWidthContent?: boolean;
  vertical?: boolean;
  itemGap?: number | string;
  children?: ReactNode;
}

const Container = styled('div')<{ inline?: boolean }>`
  display: flex;
  flex-direction: column;
  ${props => (props.inline ? 'border-bottom' : 'border')}: 1px solid #ced8e0;
  border-radius: ${props => (props.inline ? 'none' : '4px')};
  box-shadow: ${props => (props.inline ? 'none' : DEFAULT_SHADOW)};
  background: #fff;
`;

export const Header = styled.div<{ divider: boolean }>`
  ${headerPaddingCss};
  font-size: 18px;
  font-weight: 500;
  border-bottom: ${props => (props.divider ? '1px solid #ced8e0' : 'none')};
`;

const Body = styled.div<{
  allowBodyScroll?: boolean;
  noPadding?: boolean;
  itemGap: Props['itemGap'];
  vertical?: boolean;
}>`
  ${props => !props.noPadding && 'padding: 15px 24px;'}
  ${props => props.allowBodyScroll && 'overflow-y: auto;'}
  ${props => props.itemGap && 'display: flex;'}
  ${props => props.itemGap && 'gap: 15px;'}
  ${props => props.vertical && 'flex-direction: column;'}
`;

const Card: React.FC<Props> = ({
  id,
  title,
  children,
  style,
  inline,
  withTitleDivider = true,
  loading,
  allowBodyScroll = false,
  fullWidthContent,
  itemGap,
  vertical,
  ...props
}) => {
  return (
    <Container id={id} style={style} inline={inline} {...props}>
      {title && <Header divider={withTitleDivider}>{title}</Header>}
      <Body
        className="custom-card-body"
        allowBodyScroll={allowBodyScroll}
        noPadding={fullWidthContent}
        itemGap={itemGap}
        vertical={vertical}>
        {loading ? <Skeleton /> : children}
      </Body>
    </Container>
  );
};

interface CardHeaderProps {
  titleId: string;
  titleValues?: Record<string, React.ReactNode>;
  descriptionId: string;
  descriptionValues?: Record<string, React.ReactNode | ((parts: string | ReactNode) => ReactNode)>;
  link?: string;
  linkMessageId?: string;
  warning?: string;
  descriptionExtensionComponent?: React.ReactElement; // Used to display additional information in the description
  headerAction?: React.ReactNode;
  titleAction?: React.ReactNode;
}

export const CardHeader: React.FC<CardHeaderProps> = ({
  titleId,
  titleValues,
  descriptionId,
  descriptionValues,
  link,
  linkMessageId = 'link.explanation.more',
  warning,
  descriptionExtensionComponent,
  headerAction
}) => {
  return (
    <CardHeaderContainer style={{ width: '100%' }}>
      <TextContent>
        <TitleContainer>
          <FormattedMessage data-e2e="card-header-text" id={titleId} values={titleValues} />
          {headerAction}
        </TitleContainer>
        <CardDescription>
          <FormattedMessage id={descriptionId} values={descriptionValues} />{' '}
          {link && (
            <Link url={link}>
              <FormattedMessage id={linkMessageId} />
            </Link>
          )}
          {warning && <StyledAlert message={<FormattedMessage id={warning} />} showIcon closable />}
          {descriptionExtensionComponent}
        </CardDescription>
      </TextContent>
    </CardHeaderContainer>
  );
};

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CardDescription = styled.div`
  margin-top: 10px;
  color: ${({ theme }) => theme.primaryBlack};
  font-size: 13px;
  font-weight: 100;
  white-space: pre-wrap;
`;

const StyledAlert = styled(Alert)`
  margin-top: 10px;
`;

const CardHeaderContainer = styled.div`
  width: 100%;
  display: flex;
`;

export default Card;
