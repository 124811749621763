import { ConfigurationPropertiesStore } from 'stores/mobx/configuration-properties.store';
import { action, computed, observable } from 'mobx';
import some from 'lodash/some';
import { ConfigurationScope } from '@env0/configuration-service/api.enum';
import { ConfigurationPropertyStore } from 'stores/mobx/configuration-property.store';
import type { ConfigurationProperty } from 'types/api.types';
import pickBy from 'lodash/pickBy';
import map from 'lodash/map';
import type { EnvironmentImportApi } from '@env0/environment-import-service/api';
import { HierarchicalVariablesStore } from 'stores/mobx/hierarchical-variables-store';

export type ConfigurationVariableScopes = Record<
  string,
  { scope: ConfigurationScope; scopeId?: string; projectId: string; environmentId?: string }
>;

export class EnvironmentImportVariablesStore extends HierarchicalVariablesStore {
  @observable
  private environmentImportConfigurationPropertiesStores: Record<string, ConfigurationPropertiesStore> = {};
  private configurationPropertiesHierarchy: Record<string, string[]> = {};

  public init(storeScopes: ConfigurationVariableScopes, configurationHierarchy: Record<string, string[]>) {
    this.environmentImportConfigurationPropertiesStores = Object.fromEntries(
      Object.keys(storeScopes).map(scopeKey => [scopeKey, new ConfigurationPropertiesStore(this.service)])
    );
    this.configurationPropertiesHierarchy = configurationHierarchy;
  }

  public async load(
    scopes: ConfigurationVariableScopes,
    discoveredEnvironments: EnvironmentImportApi.DiscoveredUnimportedEnvironment[]
  ) {
    await Promise.allSettled(
      Object.entries(scopes).map(([storeKey, variableScope]) => {
        const loadedStore: ConfigurationPropertiesStore = this.environmentImportConfigurationPropertiesStores[storeKey];
        loadedStore.resetConfigurationSetChanges();
        return loadedStore.loadProperties(variableScope, true);
      })
    );

    (discoveredEnvironments as EnvironmentImportApi.DiscoveredEnvironment[])
      .filter(env => env.status === 'discovered')
      .forEach(discoveredEnvironment => {
        const loadedStore: ConfigurationPropertiesStore =
          this.environmentImportConfigurationPropertiesStores[discoveredEnvironment.id!];
        discoveredEnvironment.discoveredVariables?.map(variable =>
          loadedStore.createConfigurationPropertyIfNotExists(variable)
        );
      });
  }

  public propagateAllVariablesToChildScopes() {
    this.propagateVariableChanges(this.allImportStoresProjectVariables);
  }

  public getConfigurationPropertiesStoreById(storeKey: string) {
    return this.environmentImportConfigurationPropertiesStores[storeKey];
  }

  public getConfigurationChanges(storeKey: string) {
    return this.getConfigurationPropertiesStoreById(storeKey).getConfigurationPropertiesChangesForDeployment();
  }

  /** Get the ids of the projects / the discovered envs which have errors */
  @computed get failedStoreKeys() {
    return map(
      pickBy(this.environmentImportConfigurationPropertiesStores, store => store.hasError),
      (_, alias) => alias
    );
  }

  @computed get hasError() {
    return some(this.environmentImportConfigurationPropertiesStores, store => store.hasError);
  }

  @computed get allImportStoresProjectVariables() {
    const allStores = Object.values(this.environmentImportConfigurationPropertiesStores);
    return allStores
      .flatMap(store => store.allVariablesIncludingDeleted)
      .filter(variable => variable.scope === ConfigurationScope.PROJECT);
  }

  buildPropagatedCreatedVariable(
    childStore: ConfigurationPropertiesStore,
    changedConfigurationProperty: ConfigurationPropertyStore
  ) {
    const newData: ConfigurationProperty = {
      ...changedConfigurationProperty.data,
      scopeId: childStore.configurationsScope.scopeId,
      scope: ConfigurationScope.PROJECT,
      id: changedConfigurationProperty.id
    };
    const newProperty = new ConfigurationPropertyStore(childStore, newData);
    newProperty.initialData = changedConfigurationProperty.initialData;
    return newProperty;
  }

  buildPropagatedUpdatedVariable(
    changedConfigurationProperty: ConfigurationPropertyStore,
    childStore: ConfigurationPropertiesStore
  ) {
    return {
      ...changedConfigurationProperty.data,
      scopeId: childStore.configurationsScope.scopeId
    };
  }

  getSubscribedStores() {
    return this.allImportStoresProjectVariables;
  }

  getRelevantStoresForPropagation(
    changedConfigurationProperty: ConfigurationPropertyStore
  ): ConfigurationPropertiesStore[] {
    return Object.entries(this.environmentImportConfigurationPropertiesStores)
      .filter(([scopeKey]) => {
        const changedId = changedConfigurationProperty.scopeId;
        return changedId && this.configurationPropertiesHierarchy[changedId]?.includes(scopeKey);
      })
      .map(([, childStore]) => childStore);
  }

  @action
  public cleanup() {
    this.environmentImportConfigurationPropertiesStores = {};
    this.configurationPropertiesHierarchy = {};
    this.disposeChanges?.();
  }
}
