import BaseService from 'services/base-service';
import type { ConfigurationPropertiesStore } from 'stores/mobx/configuration-properties.store';
import type { ConfigurationPropertyStore } from 'stores/mobx/configuration-property.store';
import { ConfigurationScope } from '@env0/configuration-service/api.enum';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import type { ConfigurationProperty } from 'types/api.types';
import { action, reaction } from 'mobx';

export abstract class HierarchicalVariablesStore extends BaseService {
  protected disposeChanges: (() => void) | undefined;

  @action
  public registerToChanges() {
    this.disposeChanges = reaction<ConfigurationPropertyStore[]>(
      () => this.getSubscribedStores(),
      //TODO: This runs for all registered stores, regardless of the ones changed, we can improve this
      // By using mobx 6 added previous values parameter to filter out the ones that changed
      (changedConfigurationProperties: ConfigurationPropertyStore[], _) => {
        this.propagateVariableChanges(changedConfigurationProperties);
      }
    );
  }

  protected findChangedProperty(
    configurationProperties: ConfigurationPropertyStore[],
    changedConfigurationProperty: ConfigurationPropertyStore
  ) {
    return configurationProperties.find(env => {
      // if the variable was created in the UI, the ID is reflected from the parent store to the child stores
      // otherwise, it was created in server, so the matching is based on name
      const compareByName = env.data.name === changedConfigurationProperty.data.name;
      const compareById = env.id === changedConfigurationProperty.id;
      return changedConfigurationProperty.isExist ? compareByName : compareById;
    });
  }

  private propagatePropertiesChangesToChildScopes(
    changedChildStores: ConfigurationPropertiesStore[],
    changedConfigurationProperty: ConfigurationPropertyStore
  ) {
    changedChildStores.forEach(childStore => {
      const existingProperty = this.findChangedProperty(
        childStore.allVariablesIncludingDeleted,
        changedConfigurationProperty
      );

      const variableWasOverride = existingProperty && existingProperty.scope === ConfigurationScope.ENVIRONMENT;

      if (variableWasOverride) return;

      const variableWasCreated = !existingProperty && changedConfigurationProperty.isShow;
      const variableWasDeleted = existingProperty && !changedConfigurationProperty.isShow && existingProperty.isShow;
      const variableWasUpdated =
        existingProperty &&
        changedConfigurationProperty.isShow &&
        !isEqual(
          omit(changedConfigurationProperty.data, ['id', 'scopeId', 'schema']),
          omit(existingProperty.data, ['id', 'scopeId', 'schema'])
        );

      if (variableWasCreated) {
        childStore.addConfigurationProperty(
          this.buildPropagatedCreatedVariable(childStore, changedConfigurationProperty)
        );
      } else if (variableWasDeleted) {
        existingProperty.delete();
      } else if (variableWasUpdated) {
        existingProperty.propagatePropertyValue({
          ...this.buildPropagatedUpdatedVariable(changedConfigurationProperty, childStore)
        });
      }
    });
  }

  protected propagateVariableChanges(changedConfigurationProperties: ConfigurationPropertyStore[]): void {
    changedConfigurationProperties.forEach(changedEnvironmentConfig => {
      this.propagatePropertiesChangesToChildScopes(
        this.getRelevantStoresForPropagation(changedEnvironmentConfig),
        changedEnvironmentConfig
      );
    });
  }

  abstract buildPropagatedCreatedVariable(
    childStore: ConfigurationPropertiesStore,
    changedConfigurationProperty: ConfigurationPropertyStore
  ): ConfigurationPropertyStore;

  abstract buildPropagatedUpdatedVariable(
    changedConfigurationProperty: ConfigurationPropertyStore,
    childStore: ConfigurationPropertiesStore
  ): ConfigurationProperty;

  abstract getSubscribedStores(): ConfigurationPropertyStore[];

  abstract getRelevantStoresForPropagation(
    changedConfigurationProperty: ConfigurationPropertyStore
  ): ConfigurationPropertiesStore[];

  abstract cleanup(): void;
}
