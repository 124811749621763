import useStores from 'hooks/use-stores.hooks';
import { useCallback, useEffect, useMemo } from 'react';
import BrowserStorage from 'services/BrowserStorage';
import type { LoginMethod } from '../stores/mobx/auth.store';

export const useLoginLock = (loginMethod: LoginMethod) => {
  const { authStore } = useStores();

  useEffect(() => {
    authStore.loginMethod = loginMethod;
    authStore.show();
    return () => {
      authStore.hide();
      authStore.loginMethod = 'social';
    };
  }, [authStore, loginMethod]);

  const redirectTo = authStore.getRedirectToUrl();

  return {
    isAuthenticated: authStore.isAuthenticated,
    redirectTo,
    isCallbackPage: authStore.isCallbackPage
  };
};

const LAST_LOGIN_METHOD_KEY = 'lastLoginMethod';

export const useLastLoginMethod = () => {
  const lastLoginMethod: LoginMethod = useMemo(() => BrowserStorage.getItem(LAST_LOGIN_METHOD_KEY), []);

  const setLastLoginMethod = useCallback((loginMethod: LoginMethod) => {
    BrowserStorage.setItem(LAST_LOGIN_METHOD_KEY, loginMethod);
  }, []);

  return { lastLoginMethod, setLastLoginMethod };
};
