import React, { type ReactNode, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  BranchesOutlined,
  BugOutlined,
  CheckCircleOutlined,
  CloudOutlined,
  DatabaseOutlined,
  InfoCircleOutlined,
  ProjectOutlined,
  SettingOutlined
} from '@ant-design/icons';
import range from 'lodash/range';
import styled from 'types/theme.types';
import Button from 'components/common/button';
import FormButtonsContainer from 'components/common/buttons-container';
import type { WizardStepName } from 'components/common/steps';
import Steps from 'components/common/steps';
import { useNavigate } from 'react-router-dom';
import DeletePopup, { type DeletePopupProps } from 'components/common/delete-popup';
import type { WizardData } from 'components/common/use-wizard-navigator.hook';

export interface WizardContentProps {
  isEditMode?: boolean;
  wizardData: WizardData;
  children?: ReactNode;
  steps: WizardStepName[];
  deletePopupProps?: DeletePopupProps | null;
  showCancelButton?: boolean;
  readonly?: boolean;
  isSaving: boolean;
  isModal?: boolean;
}

const stepIcons: Record<WizardStepName, JSX.Element> = {
  settings: <SettingOutlined />,
  vcs: <BranchesOutlined />,
  credentials: <CloudOutlined />,
  variables: <DatabaseOutlined />,
  projects: <ProjectOutlined />,
  mapping: <ProjectOutlined />,
  testing: <BugOutlined />,
  requirements: <CloudOutlined />,
  config: <SettingOutlined />,
  'environment-details': <InfoCircleOutlined />,
  done: <CheckCircleOutlined />
};

const BackButton = ({ gotoPreviousStep, isSaving }: { isSaving: boolean; gotoPreviousStep: () => Promise<void> }) => (
  <Button onClick={gotoPreviousStep} disabled={isSaving} data-e2e={'wizard-back-button'}>
    <FormattedMessage id="back" />
  </Button>
);

const SkipButton = ({ skipStep }: { skipStep: () => Promise<void> }) => (
  <Button onClick={skipStep} data-e2e="wizard-skip-button">
    <FormattedMessage id="skip" />
  </Button>
);

const CancelButton = ({ isSaving }: { isSaving: boolean }) => {
  const navigate = useNavigate();

  return (
    <Button onClick={() => navigate(-1)} disabled={isSaving} style={{ marginLeft: '0px' }}>
      <FormattedMessage id="cancel" />
    </Button>
  );
};

/**
 *
 * This component contains only the actual content of the template wizard, without the card and the success modal.
 *
 */
const WizardContent: React.FC<WizardContentProps> = ({
  wizardData,
  isEditMode,
  children,
  steps,
  deletePopupProps,
  showCancelButton = true,
  readonly,
  isSaving,
  isModal = false
}: WizardContentProps) => {
  const {
    currentStepIndex,
    gotoNextStep,
    gotoPreviousStep,
    isCurrentStepValid,
    areAllStepsValid,
    setCurrentStep,
    isDirty,
    isSkippable,
    isSavingDisabled,
    skipStep
  } = wizardData;

  const isFormValid = isEditMode ? areAllStepsValid : isCurrentStepValid;

  const nextButtonText = useMemo(() => {
    if (isEditMode) return 'save.changes';
    if (currentStepIndex === steps.length - 1) return 'done';
    else return 'next';
  }, [isEditMode, currentStepIndex, steps.length]);

  const toStepProps = useCallback((step: WizardStepName) => {
    return {
      stepName: step,
      icon: stepIcons[step]
    };
  }, []);
  const backOrCancelButton = useMemo(() => {
    if (!isEditMode && currentStepIndex !== 0) {
      return <BackButton data-e2e="wizard-back-button" isSaving={isSaving} gotoPreviousStep={gotoPreviousStep} />;
    } else if (showCancelButton) {
      return <CancelButton data-e2e="wizard-cancel-button" isSaving={isSaving} />;
    } else return null;
  }, [isEditMode, currentStepIndex, isSaving, gotoPreviousStep, showCancelButton]);

  const stepProps = useMemo(() => range(steps.length).map(index => toStepProps(steps[index])), [toStepProps, steps]);
  return (
    <>
      <ScrolledDiv isModal={isModal}>
        <Steps
          isNew={!isEditMode}
          currentStepIndex={currentStepIndex}
          steps={stepProps}
          onClick={index => setCurrentStep(steps[index])}
        />
        <HiderDiv data-e2e="wizard-step-content-container" currentStepIndex={currentStepIndex}>
          {children}
        </HiderDiv>
      </ScrolledDiv>
      <ButtonsContainerDiv>
        <StyledFormButtonsContainer>
          {isSkippable && <SkipButton skipStep={skipStep} />}
          {!!deletePopupProps && <DeletePopup {...deletePopupProps} />}
        </StyledFormButtonsContainer>
        <FormButtonsContainer>
          {backOrCancelButton}
          <Button
            data-e2e="wizard-save-button"
            type="primary"
            onClick={gotoNextStep}
            disabled={readonly || !isFormValid || isSaving || !isDirty || isSavingDisabled}
            isLoading={isSaving}>
            <FormattedMessage id={nextButtonText} />
          </Button>
        </FormButtonsContainer>
      </ButtonsContainerDiv>
    </>
  );
};

export default WizardContent;

const HiderDiv = styled.div<{ currentStepIndex: number }>`
  & > div:not(:nth-of-type(${({ currentStepIndex }) => currentStepIndex + 1})) {
    display: none;
  }
`;
HiderDiv.displayName = 'HiderDiv';

const ButtonsContainerDiv = styled.div`
  display: flex;
  position: sticky;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-end;
`;

const ScrolledDiv = styled.div<{ isModal: boolean }>`
  overflow-y: ${({ isModal }) => (isModal ? 'auto' : 'inherit')};
  height: ${({ isModal }) => (isModal ? '100%' : 'inherit')};
  max-height: ${({ isModal }) => (isModal ? '500px' : 'inherit')};
}`;

// add a css style to FormButtonsContainer with justifyContent: flex-start
const StyledFormButtonsContainer = styled(FormButtonsContainer)`
  justify-content: flex-start;
`;
