import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginLayout from 'components/login/login-layout';
import LoginLeftContent from 'components/login/login-left-content';
import Loader from 'components/common/loader';
import { observer } from 'mobx-react';
import useStores from 'hooks/use-stores.hooks';
import { useAsyncPromise } from 'hooks/use-async-promise';
import { useLoginLock } from 'hooks/use-login-lock';

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const WebsiteLogin: React.FunctionComponent = () => {
  const { isAuthenticated } = useLoginLock('social');
  const query = useQuery();
  const navigate = useNavigate();
  const { authStore } = useStores();

  useAsyncPromise(async () => {
    try {
      if (!isAuthenticated) {
        const rawToken = query.get('token');
        if (rawToken) {
          const passedToken = JSON.parse(rawToken);
          await authStore.afterSuccessLogin(passedToken);
        }
      }
    } finally {
      navigate('/');
    }
  }, [authStore, navigate, isAuthenticated, query]);

  return (
    <LoginLayout
      data-e2e={`login-layout`}
      pageTitleId={'navigation.login'}
      leftContent={<LoginLeftContent />}
      rightContent={<Loader />}
    />
  );
};

export default observer(WebsiteLogin);
